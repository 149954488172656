import { GrowthBook } from '@growthbook/growthbook-react';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { globalHistory } from '@reach/router';
import { isDevelopmentOrStaging } from './environment';
import {
  credentials as clientKey,
  brand,
  region,
} from '../localizations/current-locale';
import { getCookie } from './cookies';

const market = region.toLowerCase();
const brandCapitalized = brand.charAt(0).toUpperCase() + brand.slice(1);

const getDeviceType = () => (typeof navigator !== 'undefined' && /Mobi/i.test(navigator?.userAgent) ? 'mobile' : 'desktop');

const hasStatisticsConsent = () => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return false;
  }
  const consentCookie = document.cookie;
  return consentCookie?.includes('statistics:true');
};

export const useSetupGrowthbook = () => {
  const externalId = getCookie('external_id');
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const viewedExperiments = useRef(new Set());
  const dataLayerEventsPushed = useRef(new Set());
  const experimentResults = useRef(new Map());
  const growthbook = useMemo(() => new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey,
    enableDevMode: isDevelopmentOrStaging(),
    attributes: {
      anonymous_id: externalId,
      application_id: '', // Will be set in repos where the id is available
      customer_id: '', // Will be set in other repos when the new login is implemented
      brand: brandCapitalized,
      market,
      device: getDeviceType(),
    },
    trackingCallback: (experiment, result) => {
      if (!viewedExperiments.current.has(experiment.key)) {
        viewedExperiments.current.add(experiment.key);
        experimentResults.current.set(experiment.key, result);
      }
      if (
        hasStatisticsConsent()
          && !dataLayerEventsPushed.current.has(experiment.key)
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'experiment_viewed',
          experiment_id: experiment.key,
          variation_id: result.key,
          anonymous_id: externalId,
          application_id: '',
          customer_id: '',
        });
        dataLayerEventsPushed.current.add(experiment.key);
      }
    },
  }), [externalId]);

  useEffect(() => {
    growthbook
      ?.init({ streaming: isDevelopmentOrStaging() })
      .then(() => {
        setFeaturesLoaded(true);
        globalHistory.listen(() => {
          growthbook.setURL(window.location.href);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error initializing GrowthBook:', error);
      });
  }, [growthbook]);

  useEffect(() => {
    const onConsentChanged = () => {
      if (hasStatisticsConsent() && featuresLoaded) {
        viewedExperiments.current.forEach((experimentKey) => {
          if (!dataLayerEventsPushed.current.has(experimentKey)) {
            const result = experimentResults.current.get(experimentKey);
            if (result) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'experiment_viewed',
                experiment_id: experimentKey,
                variation_id: result.key,
                anonymous_id: externalId,
                application_id: '',
                customer_id: '',
              });
              dataLayerEventsPushed.current.add(experimentKey);
            }
          }
        });
      }
    };

    window.addEventListener('CookiebotOnConsentReady', onConsentChanged);
    window.addEventListener('CookiebotOnAccept', onConsentChanged);
    window.addEventListener('CookiebotOnDecline', onConsentChanged);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', onConsentChanged);
      window.removeEventListener('CookiebotOnAccept', onConsentChanged);
      window.removeEventListener('CookiebotOnDecline', onConsentChanged);
    };
  }, [featuresLoaded, externalId]);

  return growthbook;
};

export const updateGrowthBookAttributes = (growthbook, attributes) => {
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  });
};

